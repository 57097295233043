import * as React from "react"
import { useCallback, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useAuth0 } from "@auth0/auth0-react"

import { ClientsService } from "../api"

import BasicHeader from "./BasicHeader"
import LoginButton from "./LoginButton"

/* Making at least the useEffect that ends up calling load depend on isAuthenticated
   ensures that the header reloads after the user logs in.  But it also allows us to
   avoid needlessly calling a secure endpoint with a 401 response while the user is
   anonymous. */
async function load(isAuthenticated: boolean) {
  let stylistUuid = process.env.REACT_APP_DEFAULT_STYLIST
  if (isAuthenticated) {
    const profile = await ClientsService.retrieveClientProfile()
    const favs = profile.favorite_stylists
    if (favs != null && favs.length > 0) stylistUuid = favs[0].uuid
  }
  return stylistUuid
}

const Header: React.FC = () => {
  const { isAuthenticated } = useAuth0()
  const [mobileNavOpen, setMobileNavOpen] = useState(false)

  const [stylistUuid, setStylistUuid] = useState<string>()

  const loadHook = useCallback(async () => {
    setStylistUuid(await load(isAuthenticated))
  }, [isAuthenticated])

  useEffect(() => {
    loadHook()
  }, [loadHook])

  const navigation = [
    { name: "Bookings", href: "/bookings" },
    { name: "Messages", href: `/messages/${stylistUuid}` },
    { name: "Profile", href: "/profile" },
    { name: "Install", href: "/install" },
  ]

  return (
    <>
      <BasicHeader>
        <div className="ml-10 ml-auto hidden space-x-8 lg:block">
          {isAuthenticated &&
            navigation.map(link => (
              <Link
                key={link.name}
                to={link.href}
                className="text-base font-medium text-indigo-600 hover:text-indigo-700"
              >
                {link.name}
              </Link>
            ))}
        </div>
        <div className="ml-10 hidden space-x-4 lg:block">
          <LoginButton />
        </div>
        <div
          onClick={() => setMobileNavOpen(!mobileNavOpen)}
          className="inline-block rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-base font-medium text-white hover:bg-indigo-700 lg:hidden"
        >
          Menu
        </div>
      </BasicHeader>
      {mobileNavOpen && (
        <nav className="mobile-nav sm:block lg:hidden">
          <div className="mt-12 ml-auto pt-12 text-center lg:block">
            {!isAuthenticated && (
              <h3 className="text-2xl font-bold text-indigo-100">
                Sign in to see your appointments
              </h3>
            )}
            {isAuthenticated &&
              navigation.map(link => (
                <div key={link.name}>
                  <Link
                    to={link.href}
                    onClick={() => setMobileNavOpen(false)}
                    className="mb-6 inline-block text-3xl font-bold text-indigo-100 hover:text-indigo-50"
                  >
                    {link.name}
                  </Link>
                </div>
              ))}
            <div className="mt-12">
              <LoginButton />
            </div>
          </div>
          <button
            onClick={() => setMobileNavOpen(!mobileNavOpen)}
            className="mobile-nav-close absolute inline-block rounded-md border border-transparent bg-gray-500 py-2 px-4 text-base font-medium text-white hover:bg-opacity-75 lg:hidden"
          >
            Close
          </button>
        </nav>
      )}
    </>
  )
}

export default Header
